import DOMPurify from "dompurify";
import { marked } from "marked";

const purify = DOMPurify(window);

export let actionStart = (src) => src.match(/\|\|\|/)?.index;

let actionOptions = {};

export function configureActions(
    options = {
        enabledActions: {
            answers: true,
            scenarios: true,
            rating: true,
            redirect: true,
            end: true,
            switch: true,
            tip: true,
        },
    },
) {
    actionOptions = options;
}

configureActions();

const ACTIONS = [
    {
        name: "answers",
        level: "inline",
        start: actionStart,
        tokenizer: function (src) {
            const rule = /^\|\|\|---answers\?b=([\d]+);$/i;
            const match = rule.exec(src);
            if (match) {
                return {
                    type: "answers",
                    raw: match[0],
                    text: "",
                    blockId: match[1],
                    tokens: [],
                };
            }
        },
        renderer: function (token) {
            if (!actionOptions.enabledActions.answers) {
                return "";
            }
            // Pulled this: <div class="animate-pulse w-full py-2"><div class="h-3 bg-slate-200 w-48 rounded-lg"></div></div>
            return `<div data-action="answers" data-action-params="&b=${token.blockId}" data-action-processed="false"></div>`;
        },
    },
    {
        name: "scenarios",
        level: "inline",
        start: actionStart,
        tokenizer: function (src) {
            const rule = /^\|\|\|---scenarios\?ids=([\d%2C]+);$/i;
            const match = rule.exec(src);
            if (match) {
                return {
                    type: "scenarios",
                    raw: match[0],
                    text: "",
                    ids: match[1],
                    tokens: [],
                };
            }
        },
        renderer: function (token) {
            if (!actionOptions.enabledActions.scenarios) {
                return "";
            }
            // Pulled this: <div class="animate-pulse w-full py-2"><div class="h-3 bg-slate-200 w-48 rounded-lg"></div></div>
            return `<div data-action="scenarios" data-action-params="&ids=${token.ids}" data-action-processed="false"></div>`;
        },
    },
    {
        name: "chainOfReasoning",
        level: "block",
        // Here we want to match aggresively with ||| to avoid rendering anything
        // whatsover until we get the end of it. In the tokenizer we declare the true
        // regex.
        start: actionStart,
        tokenizer: function (src) {
            const rule = /\|\|\|---HIDE:START;([\s\S]*?)\|\|\|---HIDE:END;/;
            const match = rule.exec(src);
            if (match) {
                const token = {
                    type: "chainOfReasoning",
                    raw: match[0],
                    text: match[0].trim(),
                    tokens: [],
                };
                this.lexer.inline(token.text, token.tokens);
                return token;
            }
        },
        renderer: function () {
            // We don't need to render anything for the the chain of reasoning,just an empty string.
            return "";
        },
    },
    {
        name: "rating",
        level: "inline",
        start: actionStart,
        tokenizer: function (src) {
            const rule = "|||---rating;";
            const index = src.indexOf(rule);
            if (index > -1) {
                if (src.trim() === rule) {
                    return {
                        type: "rating",
                        raw: src,
                        prefix: "",
                        suffix: "",
                    };
                } else {
                    let prefix = src.substring(0, index);
                    let suffix = src.substring(index + (rule.length + 1));
                    const token = {
                        type: "rating",
                        raw: src,
                        prefix: this.lexer.inlineTokens(prefix),
                        suffix: this.lexer.inlineTokens(suffix),
                    };
                    return token;
                }
            }
        },
        renderer: function (token) {
            if (!actionOptions.enabledActions.rating) {
                return "";
            }
            // Pulled this: <div class="animate-pulse w-full py-2"><div class="h-3 bg-slate-200 w-48 rounded-lg"></div></div>
            return `${this.parser.parseInline(token.prefix)}<div data-action="rating" data-action-processed="false"></div>${this.parser.parseInline(token.suffix)}`;
        },
    },
    {
        name: "redirect",
        level: "inline",
        start: actionStart,
        tokenizer: function (src) {
            const rule = /^\|\|\|---redirect\?b=([\d]+);$/i;
            const match = rule.exec(src);
            if (match) {
                return {
                    type: "redirect",
                    raw: match[0],
                    text: "",
                    blockId: match[1],
                    tokens: [],
                };
            }
        },
        renderer: function (token) {
            if (!actionOptions.enabledActions.redirect) {
                return "";
            }

            return `<div data-action="redirect" data-action-params="&b=${token.blockId}" data-action-processed="false" class="hidden"></div>`;
        },
    },
    {
        name: "end",
        level: "inline",
        start: (str) => str.indexOf("|||---end;"),
        tokenizer: function (src) {
            const rule = "|||---end;";
            const index = src.indexOf(rule);

            // We match `|||---end;` as a single string.
            // or as a substring within a string.
            if (index > -1) {
                if (src.trim() === rule) {
                    return {
                        type: "end",
                        raw: src,
                        prefix: "",
                        suffix: "",
                    };
                } else {
                    let prefix = src.substring(0, index);
                    let suffix = src.substring(index + (rule.length + 1));
                    const token = {
                        type: "end",
                        raw: src,
                        prefix: this.lexer.inlineTokens(prefix),
                        suffix: this.lexer.inlineTokens(suffix),
                    };
                    return token;
                }
            }
        },
        renderer: function (token) {
            if (!actionOptions.enabledActions.end) {
                return "";
            }

            return `${this.parser.parseInline(token.prefix)}<span class="hidden" data-action="end" data-action-processed="false"></span>${this.parser.parseInline(token.suffix)}`;
        },
        childTokens: ["prefix", "suffix"],
    },
    {
        name: "switch",
        level: "inline",
        start: actionStart,
        tokenizer: function (src) {
            const rule = /^\|\|\|---switch\?script=(.+);$/i;
            const match = rule.exec(src);
            if (match) {
                return {
                    type: "switch",
                    raw: match[0],
                    text: "",
                    script: match[1],
                    tokens: [],
                };
            }
        },
        renderer: function (token) {
            if (!actionOptions.enabledActions.switch) {
                return "";
            }

            return `<div data-action="switch" data-action-params="&script=${token.script}" data-action-processed="false" class="hidden"></div>`;
        },
    },
    {
        name: "tip",
        level: "inline",
        start: actionStart,
        tokenizer: function (src) {
            const rule = /^\|\|\|---tip\?tip_id=([\d]+);$/i;
            const match = rule.exec(src);
            if (match) {
                return {
                    type: "tip",
                    raw: match[0],
                    text: "",
                    tip_id: match[1],
                    tokens: [],
                };
            }
        },
        renderer: function (token) {
            if (!actionOptions.enabledActions.tip) {
                return "";
            }

            return `<div data-action="tip" data-action-params="&tip_id=${token.tip_id}" data-action-processed="false" class="hidden"></div>`;
        },
    },
];

marked.use({
    breaks: true,
    gfm: true,
    silent: true,
    extensions: ACTIONS,
});

export function sanitize(str) {
    if (!str) {
        return str;
    }

    return purify.sanitize(str);
}

export function toMarkdown(str, options = { inline: false }) {
    if (!str) {
        return str;
    }

    try {
        let emit = true;
        let raw = str.replaceAll("<br>", "\n");
        let fn = options.inline ? marked.parseInline : marked.parse;

        marked.use({
            hooks: {
                preprocess: (src) => {
                    if (actionStart(src) !== undefined) {
                        emit = false;
                    }

                    return src;
                },
                postprocess: (html) => {
                    if (!emit && actionStart(html) === undefined) {
                        emit = true;
                    }

                    return purify.sanitize(html);
                },
            },
        });

        let result = fn(raw);
        return emit ? result : null;
    } catch (e) {
        return str;
    }
}

export function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
